import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as AgenturvorstellungStyles from "./agenturvorstellung.module.css"
import "../../layout/layout.css"
import { StaticImage } from "gatsby-plugin-image"
import {Container} from "react-bootstrap"

const AgenturvorstellungMobile = () => {

  var kurzportrait = (
    <>
      <Container>
        <div className={`row pt-5 ${AgenturvorstellungStyles.sectionheadline}`}>
          <div className="text-center w-100"><h3>ECKDATEN</h3></div>
        </div>
        <div className={`row`}>
          <div className="col-12 p-4">
            <StaticImage
              src="../../../images/agentur/gruendung.svg"
              placeholder="blurred"
            />
          </div>
          <div className="col-12 p-4">
            <StaticImage
              src="../../../images/agentur/mitarbeiter.svg"
              placeholder="blurred"
            />
          </div>
          <div className="col-12 p-4">
            <StaticImage
              src="../../../images/agentur/zuhause.svg"
              placeholder="blurred"
            />
          </div>
          <div className="col-12 p-4">
            <StaticImage
              src="../../../images/agentur/vorstand.svg"
              placeholder="blurred"
            />
          </div>
        </div>
      </Container>
    </>
  )


  var kunden = (
    <>
      <Container>
      <div className={`row pt-5 ${AgenturvorstellungStyles.sectionheadline}`}>
        <div className="text-center w-100"><h3>KUNDEN</h3></div>
      </div>      
        <div className={`row`}>
          <div className="col-6">
            <StaticImage
              src="../../../images/agentur/logos-1.svg"
              placeholder="blurred"
            />
          </div>
          <div className="col-6">
            <StaticImage
              src="../../../images/agentur/logos-2.svg"
              placeholder="blurred"
            />
          </div>
          </div>
          <div className={`row mt-3 pt-5`}>
          <div className="col-6">
            <StaticImage
              src="../../../images/agentur/logos-3.svg"
              placeholder="blurred"
            />
          </div>
          <div className="col-6">
            <StaticImage
              src="../../../images/agentur/logos-4.svg"
              placeholder="blurred"
            />
          </div>
        </div>
      </Container>
    </>
  )


  var kompetenzen = (
    <>
      <Container>
      <div className={`row pt-5 ${AgenturvorstellungStyles.sectionheadline}`}>
        <div className="text-center w-100"><h3>KOMPETENZEN</h3></div>
      </div>        
        <div className={`row`}>
          <div className="col-12 p-4">
            <StaticImage
              src="../../../images/agentur/beratung-konzept.png"
              placeholder="blurred"
              className="height60"
              objectFit="contain"
            />
          </div>
          <div className="col-12 p-4">
            <StaticImage
              src="../../../images/agentur/kreation-programmierung.png"
              placeholder="blurred"
              className="height60"
              objectFit="contain"
            />
          </div>
          <div className="col-12 p-4">
            <StaticImage
              src="../../../images/agentur/redaktion-produktion.png"
              placeholder="blurred"
              className="height60"
              objectFit="contain"
            />
          </div>
        </div>
      </Container>
    </>
  )

  let generateSlide = headline => {
    return (
      <div className={`row`}>
        <div className="col-12 px-0 text-center">
          <h2 className="text-black">{headline}</h2>
        </div>
      </div>
    )
  }

  var swiperData = [
    { content: kurzportrait },
    { content: kunden },
    { content: kompetenzen },
  ]

  return (
    <>
      <div className="container-fluid">
        {swiperData.map((t, i) => (
          generateSlide(t.content)
        ))}
      </div>
    </>
  )
}

export default AgenturvorstellungMobile