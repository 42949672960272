import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SectionWrap from "../../sectionWrap"
import * as AgenturvorstellungStyles from "./agenturvorstellung.module.css"
import "../../layout/layout.css"
import BackgroundVideo from "../../header/backgroundVideo"
import { StaticImage } from "gatsby-plugin-image"
import { SwiperSlide } from "swiper/react"
import SwiperDefault from "../../swiperDefault"
import bgVid1 from '../../../images/agentur/steckbrief-1.mp4'
import bgVid2 from '../../../images/agentur/steckbrief-2.mp4'
import bgVid3 from '../../../images/agentur/steckbrief-3.mp4'
import { Container } from "react-bootstrap"

const Agenturvorstellung = () => {
  const data = useStaticQuery(graphql`
    query MyQueryAgenturvorstellungDesktop {
      pic1: file(relativePath: { eq: "images/agenturportrait01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  var kurzportrait = (
    <>
      <div className={`position-absolute w-100 text-center ${AgenturvorstellungStyles.sectionheadline}`}>
        <div><h3>ECKDATEN</h3></div>
      </div>
      <Container>
        <BackgroundVideo bgMode="local" bgVideo={bgVid1} bgCol="#ffffff" bgImage={data.pic1.childImageSharp.gatsbyImageData} />
        <div className={`row fullHeight vcenterRowContent`}>
          <div className="col">
            <StaticImage
              src="../../../images/agentur/gruendung.svg"
              placeholder="blurred"
              className="height50"
              objectFit="contain"
            />
          </div>
          <div className="col">
            <StaticImage
              src="../../../images/agentur/mitarbeiter.svg"
              placeholder="blurred"
              className="height50"
              objectFit="contain"
            />
          </div>
          <div className="col">
            <StaticImage
              src="../../../images/agentur/zuhause.svg"
              placeholder="blurred"
              className="height50"
              objectFit="contain"
            />
          </div>
          <div className="col">
            <StaticImage
              src="../../../images/agentur/vorstand.svg"
              placeholder="blurred"
              className="height50"
              objectFit="contain"
            />
          </div>
        </div>
      </Container>
    </>
  )


  var kunden = (
    <>
      <div className={`position-absolute w-100 text-center ${AgenturvorstellungStyles.sectionheadline}`}>
        <div><h3>KUNDEN</h3></div>
      </div>
      <Container>
        <BackgroundVideo bgMode="local" bgVideo={bgVid2} bgCol="#ffffff" bgImage={data.pic1.childImageSharp.gatsbyImageData} />
        <div className={`row fullHeight vcenterRowContent`}>
          <div className="col">
            <StaticImage
              src="../../../images/agentur/logos-1.svg"
              placeholder="blurred"
            />
          </div>
          <div className="col">
            <StaticImage
              src="../../../images/agentur/logos-2.svg"
              placeholder="blurred"
            />
          </div>
          <div className="col">
            <StaticImage
              src="../../../images/agentur/logos-3.svg"
              placeholder="blurred"
            />
          </div>
          <div className="col">
            <StaticImage
              src="../../../images/agentur/logos-4.svg"
              placeholder="blurred"
            />
          </div>
        </div>
      </Container>
    </>
  )


  var kompetenzen = (
    <>
      <div className={`position-absolute w-100 text-center ${AgenturvorstellungStyles.sectionheadline}`}>
        <div><h3>KOMPETENZEN</h3></div>
      </div>
      <Container>
        <BackgroundVideo bgMode="local" bgVideo={bgVid3} bgCol="#ffffff" bgImage={data.pic1.childImageSharp.gatsbyImageData} />
        <div className={`row fullHeight vcenterRowContent`}>
          <div className="col">
            <StaticImage
              src="../../../images/agentur/beratung-konzept.png"
              placeholder="blurred"
              className="height60"
              objectFit="contain"
            />
          </div>
          <div className="col">
            <StaticImage
              src="../../../images/agentur/kreation-programmierung.png"
              placeholder="blurred"
              className="height60"
              objectFit="contain"
            />
          </div>
          <div className="col">
            <StaticImage
              src="../../../images/agentur/redaktion-produktion.png"
              placeholder="blurred"
              className="height60"
              objectFit="contain"
            />
          </div>
        </div>
      </Container>
    </>
  )

  let generateSlide = headline => {
    return (
      <div className={`row fullHeight vcenterRowContent ${AgenturvorstellungStyles.sectionDefaultBack}`}>
        <div className="col-12 px-0 text-center">
          <h2 className="text-white">{headline}</h2>
        </div>
      </div>
    )
  }

  var swiperData = [
    { content: kurzportrait },
    { content: kunden },
    { content: kompetenzen },
  ]

  return (
    <SectionWrap bgCol="#ffffff" className="" pin={false} snap={true}>
      <SwiperDefault slidesPerView={1} spaceBetween={0} effect={"slide"} navi={true}>
        {swiperData.map((t, i) => (
          <SwiperSlide key={`agentur-swipe-${i}`}>
            {t.type == undefined ? t.content : generateSlide(t.content)}
          </SwiperSlide>
        ))}
      </SwiperDefault>
    </SectionWrap>

  )
}

export default Agenturvorstellung