import React from "react"
import { Link } from "gatsby"
import * as styles from "./headline.module.css"
import "../layout/layout.css"

const Headline = (props) => {

  return (
    <div className={`${styles.infocolumn} ${props.nomargin ? "mx-0" : ""}`} >

      {/* static folder pic */}
      {props.skypic &&
        <img src={`/${props.skypic}`} alt="" className={`${styles.skyimg}`} />
      }

      {props.skyhead &&
        <h3 className={`skyhead`}>{props.skyhead}</h3>
      }

      <h1>{props.titel}</h1>
      <p className="introtext">
        {props.text}
      </p>

      {props.showCta &&
        <Link to={props.link} className="q-btn q-btn-primary">{props.linklabel}</Link>
      }

      {props.showCtaSecondary && (
        <Link to={props.link} className="q-btn q-btn-secondary">{props.linklabel}</Link>
      )}

    </div>
  )
}

// Specifies the default values for props:
Headline.defaultProps = {
  // skypic: "dummy-logo.svg",
  skypic: "",
  skyhead: "skyhead",
  titel: "Lorem ipsum",
  text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  link: "/",
  showCta: true,
  showCtaSecondary: false,
  linklabel: "Click"
};

export default Headline