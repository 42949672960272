import React, { useEffect, useState } from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import Headline from "../../components/Headline"
// import "../../styles/index.scss"

export default function InstagramFeed(props) {
  const [posts, setPosts] = useState()

  const fields =
    "caption,media_type,media_url,permalink,thumbnail_url,timestamp"
  const token =
    "IGQVJXdnd5aFhXdzlnSUVJaXNHR0Fzc0pCbVJNeDFZAaWhKd1h5Vk1iaThBeW5rUkZAiQzhfdHRvVGJFaWVWbEJiRjdoYk4tTEFXVHpuNENlc0JpQkF0TmxhS2RCU3BiNk13dkhXQkdaUXQ0VGFZAbFo0cAZDZD"
  const url = `https://graph.instagram.com/me/media?fields=${fields}&access_token=${token}`

  const getApiData = async () => {
    const response = await fetch(url).then(response => response.json())
    if(!response.error)
      setPosts(response);
  }

  useEffect(() => {
    getApiData()
  }, [])

  /*
   https://developers.facebook.com/docs/instagram-basic-display-api/reference/media/#fields
  */
  return (
    <Container fluid>  
      <Row xs={2} md={4} className="p-2 p-md-4">
        {posts &&
          posts.data.map((post, i) => {
            let item
            if (post.media_type != "VIDEO" && i < props.posts)
              item = (
                <Col className="p-2 p-md-4">
                  <a href={post.permalink} target="_blank"><Image className="mb-0" src={post.media_url} /></a>
                  <p className="pt-2">
                    <a href={post.permalink} className="text-dark" target="_blank">
                      {post.caption.length > 102
                        ? post.caption.substring(0, 102 - 3) + " ➞"
                        : post.caption}
                    </a>
                  </p>
                </Col>
              )
            return item
          })}
      </Row>
    </Container>
  )
}

InstagramFeed.defaultProps = {
  posts: 4,
}

