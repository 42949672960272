import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import * as ffsStyles from "../components/sectionWrap/sectionWrap.module.css"
import SectionWrap from "../components/sectionWrap"
import SEO from "../components/seo"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import "../components/layout/layout.css"
import BigHeaderVideo from "../components/header/bigHeader/bigHeaderVideo"
import AgenturvorstellungDesktop from "../components/agenturvorstellung/desktop"
import AgenturvorstellungMobile from "../components/agenturvorstellung/mobile"
import Bildunterschrift from "../components/bildunterschrift"
import Introtext from "../components/introtext"
import * as agenturStyles from "./agentur.module.css"
import myLocalVid from "../images/agentur/agentur-header.mp4"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperDefault from "../components/swiperDefault"
import { Col, Container, Row } from "react-bootstrap"
import WhatsNext from "../components/whatsNext/whatsnext"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { isMobileOnly } from "react-device-detect";
import InstagramFeed from "../components/instagramFeed"


const Agentur = () => {
  const data = useStaticQuery(graphql`
    query AgenturQuery {

      ogimage: file(relativePath: { eq: "images/agentur-og.jpg" }) {
        childImageSharp {
            fixed(height: 400) {
                src
                height
                width
            }
        }
      } 

      pic1: file(relativePath: { eq: "images/space.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, grayscale: true) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pic2: file(relativePath: { eq: "images/sea.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pic3: file(relativePath: { eq: "images/mountain.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      projekt01: file(
        relativePath: { eq: "images/projekte-b2b-plattforms-partnerwelt.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }      

      projekt02: file(
        relativePath: { eq: "images/projekte-salesapps-busch-jaeger-playbook.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }  
      
      projekt03: file(
        relativePath: { eq: "images/projekte-salesapps-arag-beratungsapp.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }  

      projekt04: file(
        relativePath: { eq: "images/projekte-smarthome-bje.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }

      projekt05: file(
        relativePath: { eq: "images/projekte-telekom-infopage.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }          
      
      leistung01: file(
        relativePath: { eq: "images/agentur/beratung-slide.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }  
      
      leistung02: file(
        relativePath: { eq: "images/agentur/konzept-slide.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }  
      
      leistung03: file(
        relativePath: { eq: "images/agentur/kreation-slide.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }  
      
      leistung04: file(
        relativePath: { eq: "images/agentur/programmierung-slide.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }  
      
      leistung05: file(
        relativePath: { eq: "images/agentur/redaktion-slide.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }  
      
      leistung06: file(
        relativePath: { eq: "images/agentur/produktion-slide.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }        
          

    }
  `)

  const projects = [
    { name: "Busch-Jaeger Smart Home Konfigurator", slug: "/projekte/konfiguratoren/busch-jaeger-smart-home-konfigurator", imgSrc: data.projekt04.childImageSharp.gatsbyImageData },
    { name: "Telekom Glasfaser Infopage-Builder", slug: "/projekte/konfiguratoren/telekom-glasfaser-infopage-builder", imgSrc: data.projekt05.childImageSharp.gatsbyImageData },
    { name: "Buderus Partnerwelt", slug: "/projekte/b2b-platforms/buderus-partnerwelt", imgSrc: data.projekt01.childImageSharp.gatsbyImageData },
    { name: "Busch-Jaeger Playbook", slug: "/projekte/sales-apps/busch-jaeger-playbook", imgSrc: data.projekt02.childImageSharp.gatsbyImageData },
    { name: "ARAG Beratungsapp", slug: "/projekte/sales-apps/arag-beratungsapp", imgSrc: data.projekt03.childImageSharp.gatsbyImageData },
  ]

  const leistungen = [
    { name: "Beratung", slug: "", imgSrc: data.leistung01.childImageSharp.gatsbyImageData },
    { name: "", slug: "", imgSrc: data.leistung02.childImageSharp.gatsbyImageData },
    { name: "", slug: "", imgSrc: data.leistung03.childImageSharp.gatsbyImageData },
    { name: "", slug: "", imgSrc: data.leistung04.childImageSharp.gatsbyImageData },
    { name: "", slug: "", imgSrc: data.leistung05.childImageSharp.gatsbyImageData },
    { name: "", slug: "", imgSrc: data.leistung06.childImageSharp.gatsbyImageData }
  ]

  return (
    <Layout>
          <SEO
            title="Agentur"
            description="Im industriellen Ambiente einer alten Lederfabrik ist Tag für Tag ein versiertes Team aus Denkern und Machern für Sie im Einsatz."
            image={data?.ogimage?.childImageSharp?.fixed}
          /> 
      {/* <div className="page-container-offset"> */}

      {/* 
        header
      */}

      <BigHeaderVideo
        mode="allatonce"
        className={`${agenturStyles.child}`}
        bgVideo={myLocalVid}
        videoMode="local"
      >
        <div className="row">
          <div className="col-12 col-md-5">
            <h1>Agentur</h1>
            <p className="introtext">Bei uns stimmt mehr als nur die Fassade. Im industriellen Ambiente einer alten Lederfabrik mit Top-Ausstattung ist Tag für Tag ein versiertes Team aus Denkern und Machern für Sie im Einsatz.</p>
            <AnchorLink to="/agentur/#steckbrief" className="q-btn q-btn-primary">↓ Mehr erfahren</AnchorLink>
          </div>
          <div className="col-7"></div>
        </div>
      </BigHeaderVideo>


      <div id="steckbrief">
        {isMobileOnly &&
          <AgenturvorstellungMobile />
        }
        {!isMobileOnly &&
          <AgenturvorstellungDesktop />
        }
      </div>


      {/* Wer wir sind - half-half links swiper */}
      <Container fluid >
        <SectionWrap bgCol={"#ffffff"} pin={false} snap={true}>
          <Row className={`${ffsStyles.vcenterRowContent}`}>
            {/* left */}
            <Col xs={12} md={6} className={`mx-0 px-0`}>
              <SwiperDefault>

                <SwiperSlide>
                  <StaticImage
                    src="../images/agentur/qmarketing-logoslide.svg"
                    placeholder="blurred"
                    className="fullHeight"
                    alt=""
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <StaticImage
                    src="../images/agentur/agentur01.jpg"
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <StaticImage
                    src="../images/agentur/agentur02.jpg"
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <StaticImage
                    src="../images/agentur/agentur03.jpg"
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>

              </SwiperDefault>
            </Col>
            <Col xs={12} md={6} className={`mx-0 px-0 py-5`}>
              <Introtext skyhead="Wer wir sind" titel="Agentur für digitale Marketing- und Vertriebslösungen" text="Wir denken uns in die Strategien und Produkte unserer Kunden. Wir beraten, konzipieren, gestalten und setzen um. Wir sind Ihre Hausagentur oder fühlen uns als Partner in Ihrem Netzwerk wohl." showCta={false} />
            </Col>
          </Row>
        </SectionWrap>
      </Container>

      {/* Kunden half-half links swiper */}
      <Container fluid >
        <SectionWrap bgCol={"#ffffff"} pin={false} snap={true}>
          <Row className={`${ffsStyles.vcenterRowContent}`}>

            <Col xs={12} md={6} className={`mx-0 px-0 py-5`}>
              <Introtext skyhead="Kunden" titel="Von lokal bis international" text="Unsere Kunden sind uns viel wert – wir wollen, dass sie Erfolg haben. Und dafür, dass Beziehungen mit uns gewinnbringend sind, spricht eine oft jahrzehntelange Zusammenarbeit. Von mittelständischen Betrieben bis zum Konzern." showCta={false} />
            </Col>

            <Col xs={12} md={6} className={`mx-0 px-0 order-first order-md-last`}>
              <SwiperDefault>

                <SwiperSlide>
                  <StaticImage
                    src="../images/agentur/qmarketing-kundenslide1.svg"
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <StaticImage
                    src="../images/agentur/qmarketing-kundenslide2.svg"
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <StaticImage
                    src="../images/agentur/qmarketing-kundenslide3.svg"
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <StaticImage
                    src="../images/agentur/qmarketing-kundenslide4.svg"
                    placeholder="blurred"
                    className="fullHeight"
                  />
                </SwiperSlide>

              </SwiperDefault>
            </Col>
          </Row>
        </SectionWrap>
      </Container>

      {/* Projekte - half-half links swiper */}
      <Container fluid >
        <SectionWrap bgCol={"#ffffff"} pin={false} snap={true}>
          <Row className={`${ffsStyles.vcenterRowContent}`}>

            <Col xs={12} lg={6} className="p-0">
              <SwiperDefault slidesPerView={1} navi={true} pagination={false}>
                {projects.map(projects => (
                  <SwiperSlide style={{ width: "100%" }}>
                    <Link to={projects.slug} style={{ display: "flex", justifyContent: "center" }}>
                      <Bildunterschrift bu={projects.name} />
                      <GatsbyImage
                        image={projects.imgSrc}
                        alt=""
                        placeholder="blurred"
                        className="fullHeight"
                      />
                    </Link>
                  </SwiperSlide>
                ))}
              </SwiperDefault>
            </Col>

            <Col xs={12} md={6} className={`mx-0 px-0 py-5 contentmodul`}>
              <Introtext skyhead="Leistungen" titel="Was wir machen"
                text={
                  <div>                    
                    <Row>
                      <Col>
                        <div className="qliste">
                          <ul>
                            <li><a href="/projekte/b2b-platforms">B2B platforms</a></li>
                            <li><a href="/projekte/konfiguratoren">Konfiguratoren</a></li>
                            <li><a href="/projekte/sales-apps">Sales Apps</a></li>
                            <li><a href="/projekte/virtuelle-produktpraesentationen">Virtuelle Produktpräsentationen</a></li>
                          </ul>
                        </div>
                      </Col>
                      <Col>
                        <div className="qliste">
                          <ul>
                            <li><a href="/projekte/ecosystems">Ecosystems</a></li>
                            <li><a href="/projekte/websites">Websites</a></li>
                            <li><a href="/projekte/corporate-communications">Corporate Communications</a></li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </div>
                } showCta={false} />
            </Col>
          </Row>
        </SectionWrap>
      </Container>


      {/* 360 rundgang*/}
      <Container fluid >
        <SectionWrap bgCol={"#ffffff"} pin={false} snap={true}>
          <Row className={`${ffsStyles.vcenterRowContent}`}>

            <></>

            <Col xs={12} md={6} className={`mx-0 px-0 py-5`}>
              <Introtext skyhead="Rundgang" titel="Blick in die Agentur" text={<>Bewegen Sie sich durch unsere Besprechungsräume. Wir würden uns freuen, Sie einmal live zu begrüßen.<br/><a href="https://q360.netlify.app/" target="_blank" className="q-btn q-btn-secondary mt-3">Open Fullscreen</a></>} showCta={false} showCtaSecondary={false} linklabel="Open Fullscreen" link="https://q360.netlify.app/" />
            </Col>

            <Col xs={12} md={6} className={`mx-0 px-0`}>
              <div className="fullHeight">
                <iframe className="minheight80vh" src="https://q360.netlify.app" frameBorder="0" width="100%" height="100%" loading="lazy"></iframe>
              </div>
            </Col>
          </Row>
        </SectionWrap>
      </Container>


      <InstagramFeed posts={4}/>

      {/* <WasWirTun/> */}


      {/* <ProjekteGridHero /> */}

      {/* 
        next
      */}
      <WhatsNext text="Lernen Sie das Team kennen &rarr;" link="/team" />

    </Layout>
  )
}

export default Agentur
